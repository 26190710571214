<template>
  <v-row justify="center" class="text-center pa-lg-5">
    <v-col cols="12">
      <UserForm :user="user" @dismiss="onDone" @updated="onDone" v-if="user" />
    </v-col>
  </v-row>
</template>

<script>
import { USER_GET } from "@/constants/api";

export default {
  name: "UserEdit",
  metaInfo: { title: "Edit User" },
  components: {
    UserForm: () => import("@/views/components/User/Form"),
  },
  data: () => ({
    loading: false,
    user: null,
  }),
  computed: {
    userId() {
      return Number(this.$route.params.id);
    },
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    async fetchUser() {
      this.loading = true;
      try {
        const response = await this.$axios.get(USER_GET, {
          params: { userId: this.userId },
        });
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.user = response.data.data;
      } catch (err) {
        this.$iziToast.showError(err);
        this.onDone();
      } finally {
        this.loading = false;
      }
    },
    onDone() {
      this.$router.back();
    },
  },
};
</script>

<style scoped></style>
